<template>
  <div class="category">
    <el-table
      :data="tableData"
      style="width: 100%"
      height="calc(100vh - 100px)"
    >
      <el-table-column type="index" label="序号" />
      <el-table-column label="标题" prop="title" show-overflow-tooltip />
      <el-table-column label="描述" prop="description" show-overflow-tooltip />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <!-- 模态 -->
    <el-dialog :title="formTitle" width="50%" :visible.sync="categoryFormVisible">
      <el-form
        :model="categoryForm"
        :rules="categoryRules"
        ref="categoryForm"
        size="medium"
        label-width="80px"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="categoryForm.title" placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input v-model="categoryForm.description" type="textarea" placeholder="请输入描述" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="save">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCategoryList,
  updateCategory
} from '@/services/category'

export default {
  name: 'Category',
  data () {
    return {
      tableData: [],
      categoryForm: {},
      categoryRules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ]
      },
      formTitle: '编辑',
      categoryFormVisible: false
    }
  },
  created () {
    this.getCategoryList()
  },
  methods: {
    async getCategoryList () {
      try {
        const { data } = await getCategoryList()
        this.tableData = data.data
      } catch (e) {
        console.log(e)
      }
    },
    handleEdit (index, row) {
      if (this.$refs.categoryForm) {
        this.$refs.categoryForm.resetFields()
      }
      this.categoryForm = { ...row }
      this.categoryFormVisible = true
    },
    cancel () {
      this.categoryForm = {}
      this.categoryFormVisible = false
    },
    save () {
      this.$refs.categoryForm.validate(async valid => {
        if (valid) {
          try {
            await updateCategory(this.categoryForm.id, this.categoryForm)
            this.categoryFormVisible = false
            this.questionForm = { sort: 0 }
            this.getCategoryList()
            this.$message({
              type: 'success',
              message: '编辑成功'
            })
          } catch (err) {
            this.$message({
              type: 'error',
              message: '编辑失败'
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="" scoped></style>
